import React from "react";
import InstructionsCard from "./InstructionsCard";
import Step2Card1 from "./Step2Card1";
import Step2Card2 from "./Step2Card2";
import Step2Card2Pass from "./Step2Card2Pass";
import Step2Card2Fail from "./Step2Card2Fail";
import {useLocation} from "react-router-dom";
import {InstructionsEnum} from "../common/Constants";
import {NotFound} from "./NotFound";

function Step2() {
    
    const location = useLocation();
    const data = location.state;
    
    //variables depending on state data : daysLeft
    const requiredKeys=['candidateState','fullName','testLink','obtainedMarks','maxMarks','lastAttemptedDate','assessmentName', 'testResult'];
    
    if (data) {
        console.log('state -> ',data);
        if (requiredKeys.every(key => data.hasOwnProperty(key))) {
            return (
                <div className={"white-background"}>
                    <Step2Card1 name={data.fullName}/>
                    { (data.candidateState==='Registered' || data.candidateState==='Test_Started') && <><Step2Card2 testName = {data.assessmentName} apiLink = {data.testLink}/><InstructionsCard instructionsList = {InstructionsEnum.Step2Registered}/></> }
                    { (data.candidateState==='Test_Graded' && data.testResult==='Pass' ) && <><Step2Card2Pass testName = {data.assessmentName} candidateScore = {data.obtainedMarks} maxScore = {data.maxMarks} attemptDate = {data.lastAttemptedDate}/><InstructionsCard instructionsList = {InstructionsEnum.Step2Pass}/></>}
                    { (data.candidateState==='Test_Graded' && data.testResult==='Fail' ) && <><Step2Card2Fail testName = {data.assessmentName} daysLeft = {data.daysLeft} candidateScore = {data.obtainedMarks} maxScore = {data.maxMarks} attemptDate = {data.lastAttemptedDate}/><InstructionsCard instructionsList = {InstructionsEnum.Step2Fail}/></>}
                </div>)
        } else {
            console.log("required property is missing", data)
            return (<h2 align = {"center"}>Required Data is missing</h2>)
        }
    }else {
        console.log("state is null", data)
        return (<NotFound/>)
    }
}

export default Step2;