import React from "react";
import {Button, Card} from "antd";
import {ExportOutlined} from "@ant-design/icons";
import "./../App.css";

function Step2Card2({testName, apiLink}) {
    if(testName && apiLink) {
        return (<>
            <div className = {"card-margin-left-right card-margin-top available-font"}>Available Tests / Previous Attempts</div>
            <Card
                bordered = {false}
                loading = {false}
                type = {"inner"}
                className = {"card-margin-left-right border-radius margin-top"}
            >
                <div>
                    <b>{testName}</b>
                    <p className={"available-now-font"}>Available now</p>
                </div>
                <div className = {"button-color-blue border-radius form-field-padding2 proceed-btn-height"}>
                <Button href = {apiLink} target = {"_blank"} className={"button-color-blue"} block>Start Test<ExportOutlined/></Button>
                </div>
            </Card>
        </>);
    }else {
        console.log("required testName/apiLink is missing", testName,',', apiLink,'.')
        return (<h2 align = {"center"}>No active assessments</h2>)
    }
}

export default Step2Card2;