import React from "react";
import Step1Card1 from "./Step1Card1";
import Step1Card2 from "./Step1Card2";
import {useParams} from "react-router-dom";
import "./../App.css";
import Step1Card3 from "./Step1Card3";

function Step1() {
    
    const {center, office } = useParams()
    
    // TODO validation on center & office - regex
    console.log('center ->',center)
    console.log('office ->',office)
    
    return (
            <div className={"white-background"}>
                <Step1Card1/>
                <Step1Card2 clientInfo = {{"center": center, "office": office}}/>
                <Step1Card3/>
        </div>
    );
}

export default Step1;
