import React from "react";
import {Card} from "antd";
import {InfoCircleOutlined} from "@ant-design/icons";
import "./../App.css";
import Link from "antd/lib/typography/Link";

function Step1Card3() {
    
    return (<>
        <Card
            bordered = {true}
            loading = {false}
            type = {"inner"}
            className={"card-margin-left-right card-margin-top border-radius"}
        >
            <InfoCircleOutlined/><span> For any assistance, you can always drop mail to our support team <Link target="_blank" href='mailto:support@mettl.com'>support@mettl.com</Link></span>
        </Card>
    </>);
}

export default Step1Card3;