import React from "react";
import {Card} from "antd";
import "./../App.css";

function Step1Card1() {
    return (<>
        <Card
            bordered = {true}
            loading = {false}
            type = {"inner"}
            className={"white-background"}
        >
            <h1 className={"head-margin custom-font"}>Provide Your Details</h1>
            <span className={"sub-heading"}>Start by providing details and proceed towards test details.</span>
        </Card>
    </>);
}

export default Step1Card1;