import React from "react";
import {Card, Divider} from "antd";
import {InfoCircleOutlined, WarningFilled} from "@ant-design/icons";
import "./../App.css";
import Link from "antd/lib/typography/Link";

function InstructionsCard({instructionsList}) {
    
    return (<>
        <Card
            bordered = {true}
            loading = {false}
            type = {"inner"}
            className={"card-margin-left-right card-margin-top border-radius"}
        >
            <WarningFilled className={"red-font2"} /> <b>Important messages to follow</b>
            
            <ul className={"instructions-padding"}>
                {instructionsList}
            </ul>
            
            <Divider orientation = "right" type = {"horizontal"} orientationMargin = {10}></Divider>
            <InfoCircleOutlined/><span> For any assistance, you can always drop mail to our support team <Link target="_blank" href='mailto:support@mettl.com'>support@mettl.com</Link></span>
        </Card>
    </>);
}

export default InstructionsCard;