import "./App.css";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {Layout} from "antd";
import {NotFound} from "./components/NotFound";
import MMC_Logo from "./assets/MMC.png";
import Step1 from "./components/Step1";
import {QuestionCircleOutlined} from "@ant-design/icons";
import Step2 from "./components/Step2";
import {isMobile} from 'react-device-detect';

const {Header, Content} = Layout;

function App() {
    
    return (
        <>
        <Header className={"white-background"}>
            <img src = {MMC_Logo} alt = {"Amazon_Logo"} className={"logo"}/>
            <QuestionCircleOutlined className={"info-icon"}/>
        </Header>
        <Content>
            <Router>
                <Switch>
                    <Route path = "/register/:center/:office" exact component = {Step1}/>
                    <Route path = "/verificationDetails" exact component = {Step2}/>
                    <Route component={NotFound}/>
                </Switch>
            </Router>
            <div className={"footer-height white-background"}> </div>
            {!isMobile && <p className={"text-align-center"}><b>NOTE</b> : Web Browsers are not supported, Mobile or tablet devices are recommended for best user experience.</p>}
        </Content>
    </>);
}

export default App;
