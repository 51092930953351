const local = {
    BASE_URL: 'http://localhost:8080/api/'
}

const com = {
    BASE_URL: 'https://amazon.mettl.com/api/'
}

const xyz = {
    BASE_URL: 'https://amazon.mettl.xyz/api/'
}

const pro = {
    BASE_URL: 'https://amazon.mettl.pro/api/'
}

let env;

switch (process.env.REACT_APP_ENV) {
    case 'com':
        env = com
        break;
    case 'xyz':
        env = xyz
        break;
    case 'pro':
        env = pro
        break;
    default:
        env = local
        break;
}
export {env}