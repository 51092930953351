import React from "react";
import {Card, Tag} from "antd";
import {CheckCircleFilled} from "@ant-design/icons";

function Step2Card2({testName, candidateScore, maxScore, attemptDate }) {
    if (testName && candidateScore && maxScore && attemptDate) {
        return (<>
            <Card
                bordered = {false}
                loading = {false}
                type = {"inner"}
                className = {"card-margin-all light-grey-background border-radius"}
            >
                You have <span className = {"green-font"}><b>passed the test.</b></span> Please <b>show these details to the site manager.</b>
            </Card>
            <p className = {"card-margin-left-right available-font"}>Available Tests / Previous Attempts</p>
            <Card
                bordered = {true}
                loading = {false}
                type = {"inner"}
                className = {"card-margin-left-right border-radius margin-top"}
            >
                <div>
                    <b>{testName}</b>
                    <div><span className = {"grey-font"}>Attempted on: </span>{attemptDate}
                        <p className = {"green-font"}><CheckCircleFilled/> Passed</p>
                    </div>
                </div>
                <Tag className = {"light-grey-background border-radius light-grey-border form-field-padding start-test-button"}>Assessment Score: <b>{candidateScore}/{maxScore}</b></Tag>
            </Card>
        </>);
    } else {
        console.log("required testName/candidateScore/maxScore/attemptDate is missing",testName,',', candidateScore,',', maxScore,',', attemptDate,".")
        return (<h2 align = {"center"}>Results will be announced soon..</h2>)
    }
}

export default Step2Card2;