import React from "react";
import {Tag, Card} from "antd";
import {WarningFilled} from "@ant-design/icons";

function Step2Card2({testName, daysLeft, candidateScore, maxScore, attemptDate}) {
    if (testName && daysLeft && candidateScore && maxScore && attemptDate) {
        return (<>
            <Card
                bordered = {false}
                loading = {false}
                type = {"inner"}
                className={"card-margin-all light-grey-background border-radius"}
            ><p>You <span className={"red-font2"}><b>didn't pass the test</b></span> on your last attempt.</p>Next attempt will be available in: <b>{daysLeft} days</b>
            
            </Card>
            <div className={"card-margin-left-right available-font"}>Available Tests / Previous Attempts</div>
            <Card
                bordered = {false}
                loading = {false}
                type = {"inner"}
                className={"card-margin-left-right border-radius margin-top"}
            >
                <div>
                    <b>{testName}</b>
                    <div><span className={"grey-font"}>Attempted on: </span>{attemptDate}
                        <p className={"red-font2"}><WarningFilled /> Failed</p>
                    </div>
                </div>
                <Tag className={"light-grey-background border-radius light-grey-border form-field-padding start-test-button"}>Assessment Score: <b>{candidateScore}/{maxScore}</b></Tag>
            </Card>
        </>);
    } else {
        console.log("required testName/daysLeft/candidateScore/maxScore/attemptDate is missing", testName,',', daysLeft,',', candidateScore,',', maxScore,',', attemptDate,'.')
        return (<h2 align = {"center"}>Results will be announced soon...</h2>)
    }
}

export default Step2Card2;