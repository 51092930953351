import React, {useState} from "react";
import {Button, Card, Checkbox, Form, Input, message} from "antd";
import "./../App.css";
import {useHistory} from "react-router-dom";
import {AxiosUtils} from "../common/AxiosUtils";
import Link from "antd/lib/typography/Link";

function Step1Card2({clientInfo}) {
    
    const [form] = Form.useForm();
    
    const history = useHistory()
    
    const [isTnCAccepted, setIsTnCAccepted] = useState(false);
    
    const [messageApi, contextHolder] = message.useMessage();
    
    // const [openModal, setOpenModal] = useState(false);
    
    const [apiResponse, setApiResponse] = useState(null);
    
    const [apiError, setApiError] = useState(null);
    
    // useEffect(() => {
    //     console.log('UseEffect called : ','res : ',apiResponse,'err : ',apiError, 'clientInfo : ', clientInfo)
    // }, [apiError,apiResponse]);

    const onFinish = (formData) => {
        console.log('onFinish->',formData);
        
        delete formData['tnc'];
        verifyDetails(formData);
        
        // setOpenModal(true)
    };
    
    const verifyDetails = async (userInfo) => {
        try {
            let payload = {
                ...clientInfo,
                ...userInfo
            }
            //TestName, attempt_left
            // let json = { 'assessmentName' : "assessmentName", "candidateState": "Test_Graded","testResult": "Fail","daysLeft":1, "testLink": "https://tests.mettl.pro/take-test?ecc=9DU5B0UlzE8dgj67sy8nwO9fW0wb6YVnsEYgogo9w%2Fw%3D", "fullName": "DemoFullName", "obtainedMarks": "50", "maxMarks": "100", "lastAttemptedDate": "08/12/2023"}
            let res = await AxiosUtils().post('/validateOTP', payload)
            let json = res.data
            
            setApiResponse(json)
            console.log('api -> ',json)
            setApiError(null)
            
            history.push({
                pathname: '/verificationDetails', state: {...json}
            })
            console.log('apiResponse -> ',apiResponse)
        } catch (error) {
            setApiResponse(null)
            setApiError(prevState => error)
            
            let errMessage = error.response === undefined ? 'Failed to get candidate Details. Please try later' : error.response.data.message;
            messageApi.open({
                type: 'error',
                content: errMessage,
            });
        }
    }
    
    // const onChange = (e) => {
    //     let code = e.keyCode;
    //     let value = String.fromCharCode(code);
    //     console.log(value)
    //     e.target.value = value
    //     e.preventDefault();
    // }
    return (<>
        <Card
            bordered = {false}
            loading = {false}
            type = {"inner"}
            className={"card-margin-all border-radius"}
        >
            <Form form = {form} name = "form" labelCol = {{span: 8}} wrapperCol = {{span: 8}} autoComplete = "off" onFinish={onFinish}>
                <Form.Item className={"label-font"} label = "Full Name" name = "fullName" rules = {[{required: true, message: "Please provide your Full Name!",}]}>
                    <Input className={"border-radius form-field-padding"} placeholder = "Full Name"/>
                </Form.Item>
                <Form.Item className={"label-font"} label = "Contact Number" name = "phoneNumber" /*extra="OTP will be sent on this number for verification purposes"*/ rules = {[{required: true, message: "Please enter your Contact Number!",} ]}>
                    <Input className={"border-radius form-field-padding"} /*prefix*/ prefix = "+91" placeholder = "Contact Number" minLength={10} maxLength={10} /*onKeyPress={onChange} type={'number'}*//>
                </Form.Item>
                <Form.Item name="tnc" valuePropName="checked" rules={[{validator: (_, value) => value ? Promise.resolve(setIsTnCAccepted(true)) : Promise.reject(setIsTnCAccepted(false))}]} wrapperCol= {{span: 24}}>
                    <Checkbox>By Checking this box, you agree to our <Link target="_blank" href='https://mettl.com/terms-of-services/'>Terms</Link> and <Link target="_blank" href='https://mettl.com/privacy-notice/'>Privacy Policy</Link>.</Checkbox>
                </Form.Item>
                <Button htmlType = "submit" block className = {"button-color-blue border-radius form-field-padding proceed-btn-height"} disabled={!isTnCAccepted}>
                    Proceed
                </Button>
                {/*<Button htmlType = "submit" block className={"button-color-yellow border-radius"} onClick={onFinish}>*/}
                {/*    Verify with OTP<RightOutlined/>*/}
                {/*</Button>*/}
            </Form>
            {/*{apiResponse && openModal && <VerifyOTPModal openModal = {openModal} setOpenModal = {setOpenModal} number={apiResponse.fullName}/>}*/}
            {/*{apiError && <Tag color="error" className={"wrap-text"}>Some error occured : {apiError.response.data.message}</Tag>}*/}
            {contextHolder}
        </Card>
    </>);
}

export default Step1Card2;